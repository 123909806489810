import React, { useState } from "react";
import LandingPageView from "../../components/LandingPage/LandinPageView";
import Backdrop from "../../components/UI/Input/Modal/Backdrop";
import Modal from "../../components/UI/Input/Modal/Modal";
import RegisterForLaunchForm from "./RegisterForLaunchForm";

const LandingPage = () => {
  const [FormData, setForm] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });
  const [showModal, setShowModal] = useState(false);

  const toggleShowModalHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <LandingPageView toggleModal={toggleShowModalHandler}></LandingPageView>

      {showModal ? <Backdrop toggleModal={toggleShowModalHandler} /> : null}

      {showModal ? (
        <Modal toggleModal={toggleShowModalHandler}>
          <RegisterForLaunchForm toggleModal={toggleShowModalHandler} />
        </Modal>
      ) : null}
    </div>
  );
};

export default LandingPage;
