import React from "react";
import styled from "styled-components";

const HeroTitle = (props) => {
  return <Title>{props.children}</Title>;
};

export default HeroTitle;

const Title = styled.div`
  font-size: clamp(50px, 5vw, 80px);
  color: #fff;
  font-weight: 800;
`;
