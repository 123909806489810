import React from "react";
import styled from "styled-components";

const Backdrop = (props) => {
  const { toggleModal } = props;
  return <ModalBackdrop onClick={toggleModal} />;
};

export default Backdrop;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;
