import React from "react";
import styled from "styled-components";

import HeroTitle from "./UI/HeroTitle";
import HeroSubTitle from "./UI/HeroSubTitle";
import HeroText from "./UI/HeroText";

const DiscoveryHero = (props) => {
  const { toggleModal } = props;
  return (
    <TileWrap>
      <HeroTitle>Hear Five New Albums Every Month</HeroTitle>
      <HeroSubTitle>
        Choose one to own plus get a free compilation of 10 up and coming
        artists
      </HeroSubTitle>
      <HeroText>
        All music is curated by leading artists, A&R and music journalists in
        your chosen genre
      </HeroText>
      <SignUpButton onClick={toggleModal}>Get Started</SignUpButton>
    </TileWrap>
  );
};

export default DiscoveryHero;

const TileWrap = styled.div`
  text-shadow: 10px 10px 50px #888;
  background: rgb(10, 23, 43);
  background: linear-gradient(
    230deg,
    rgba(10, 23, 43, 1) 75%,
    rgba(0, 255, 209, 1) 100%
  );
  padding: 200px 0;

  @media (max-width: 768px) {
    padding: 100px 0;
  }
`;

const SignUpButton = styled.button`
  margin-top: 20px;
  padding: 20px 50px;
  border-radius: 500px;
  outline: none;
  border: none;
  background-color: #00ffd1;
  color: #0a172b;
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;
