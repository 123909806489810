import React from "react";
import styled from "styled-components";

const HeroText = (props) => {
  return <Text>{props.children}</Text>;
};

export default HeroText;

const Text = styled.div`
  font-size: clamp(25px, 3vw, 30px);
  color: #fff;
  font-weight: 200;
  padding: 20px;
`;
