import React from "react";
import styled from "styled-components";

const HeroSubTitle = (props) => {
  return <SubTitle>{props.children}</SubTitle>;
};

export default HeroSubTitle;

const SubTitle = styled.div`
  font-size: clamp(30px, 4vw, 50px);
  color: #fff;
  font-weight: 400;
  padding: 20px;
`;
