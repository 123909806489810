import React from "react";
import styled from "styled-components";
import { NUColors } from "../../../../CssVars";

const Modal = (props) => {
  const { toggleModal } = props;
  return (
    <ModalWindow>
      <CloseWrap>
        <CloseText onClick={toggleModal}>Close</CloseText>
      </CloseWrap>
      {props.children}
    </ModalWindow>
  );
};

export default Modal;

const ModalWindow = styled.div`
  width: 100%;
  max-width: 40%;
  height: 100%;
  max-height: 80%;
  position: fixed;
  z-index: 300;
  left: 30%;
  top: 10%;
  background-color: ${NUColors.lighterNavy};
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    max-width: 95%;

    left: 2.5%;
    border-radius: 0;
  }
`;

const CloseWrap = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px 0 0 0;
`;

const CloseText = styled.div`
  color: white;
  cursor: pointer;
  padding-right: 10px;
`;
