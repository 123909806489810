import React from "react";
import styled from "styled-components";
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { AiFillDollarCircle } from "react-icons/ai";

const FiveAlbumsTile = (props) => {
  return (
    <SmallTileWrap>
      <SmallTileTitle>Every Month</SmallTileTitle>
      <SmallTileFlexWrap>
        <FeatureWrap>
          <IconWrap>
            <FaAssistiveListeningSystems />
          </IconWrap>
          <Text>Listen to five curated albums</Text>
        </FeatureWrap>
        <FeatureWrap>
          <IconWrap>
            <AiOutlineAppstoreAdd />
          </IconWrap>
          <Text>Choose one to own</Text>
        </FeatureWrap>
        <FeatureWrap>
          <IconWrap>
            <AiFillDollarCircle />
          </IconWrap>
          <Text>The artist gets 75%</Text>
        </FeatureWrap>
      </SmallTileFlexWrap>
      {/* <IoMusicalNotesSharp /> */}
    </SmallTileWrap>
  );
};

export default FiveAlbumsTile;

const SmallTileWrap = styled.div`
  min-height: 250px;
  color: white;
  background-color: black;
`;

const SmallTileFlexWrap = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
`;

const FeatureWrap = styled.div`
  flex-basis: 100%;
  padding: 10px;
`;

const SmallTileTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: white;
  padding-top: 20px;
  font-weight: 800;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #00ffd1;
`;

const IconWrap = styled.span`
  width: 100%;
  text-align: center;
  font-size: 60px;
  color: #00ffd1;
`;
