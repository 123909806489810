import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

const LogIn = () => {
  const [form, setForm] = useState({ email: null, password: null });
  const formChangedHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(form.email, form.password);
    } catch (error) {
      console.log("ERROR SIGNING IN:::", error);
    }
  };

  return (
    <div>
      <label>email</label>
      <input onChange={formChangedHandler} type="text" name="email"></input>
      <label>password</label>
      <input
        onChange={formChangedHandler}
        type="password"
        name="password"
      ></input>
      <button onClick={submitFormHandler}>Log In</button>
    </div>
  );
};

export default LogIn;
