import React from "react";
import styled from "styled-components";

import { NUColors } from "../../CssVars";

const Footer = () => {
  return (
    <React.Fragment>
      <Wrap>
        <Col>
          <ColTitle>Artists</ColTitle>
          <ColContent>
            We are actively approaching artists through out network of artists,
            A&R and music journalists but if you feel that you would like to be
            considered as a featured artist then please get in touch via &nbsp;
            <a href="mailto: artists@nujuke.com">artists@nujuke.com</a>
          </ColContent>
        </Col>
        <Col>
          <ColTitle>Press</ColTitle>
          <ColContent>
            For all press related information please contact &nbsp;
            <a href="mailto: press@nujuke.com">press@nujuke.com</a>
          </ColContent>
        </Col>
        <Col>
          <ColTitle>Investors</ColTitle>
          <ColContent>
            For all investor related information please contact &nbsp;
            <a href="mailto: investors@nujuke.com">investors@nujuke.com</a>
          </ColContent>
        </Col>
      </Wrap>
      <Copyright>&#169; 2021 NuJuke</Copyright>
    </React.Fragment>
  );
};

export default Footer;

const Wrap = styled.div`
  display: flex;
  border-top: 1px dotted ${NUColors.electricGreen};
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  padding: 50px;
  flex-basis: 100%;
`;

const ColTitle = styled.div`
  color: ${NUColors.electricGreen};
  font-size: clamp(25px, 1.5vw, 30px);
  text-transform: uppercase;
  font-weight: 500;
`;

const ColContent = styled.div`
  padding: 10px 20px;
  font-size: clamp(18px, 1vw, 30px);
  color: ${NUColors.white};
  line-height: 1.5em;
  font-weight: 300;
`;

const Copyright = styled.div`
  width: 100%;
  color: ${NUColors.white};
  font-size: clamp(18px, 1vw, 30px);
  padding-bottom: 25px;
  font-weight: 200;
`;
