import React, { useState, Fragment } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";

import { NUColors } from "../../CssVars";

const RegisterForLaunchForm = (props) => {
  const { toggleModal } = props;

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(null);
  const [formSubmitFailed, setFormSubmitFailed] = useState(null);
  const [form, setForm] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const submitFormHandler = async (e) => {
    e.preventDefault();
    setFormIsSubmitting(true);

    const db = firebase.firestore();
    const idRef = db.collection("launchUsers").doc();

    try {
      const res = await db.collection("launchUsers").doc(idRef.id).set(form);

      console.log(res);
      setFormIsSubmitting(false);
      setFormSubmitSuccess(true);

      setTimeout(() => {
        toggleModal();
      }, [2000]);
    } catch (error) {
      console.log("error submitting emai form::: ", error);
      setFormIsSubmitting(false);
      setFormSubmitFailed(true);
    }
  };

  return (
    <Wrap>
      {formSubmitFailed === null && formSubmitSuccess === null ? (
        <Fragment>
          <Title>
            Register now for our 2021 launch and we will let you know when we
            are going live
          </Title>
          <Input
            name="firstName"
            placeholder="first name"
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
          <Input
            name="lastName"
            placeholder="last name"
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
          <Input
            name="email"
            placeholder="email"
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
          <SignUpButton disabled={formIsSubmitting} onClick={submitFormHandler}>
            {formIsSubmitting ? "Loading" : "Submit"}
          </SignUpButton>
        </Fragment>
      ) : formSubmitSuccess === true ? (
        <Title>Congratulations you have now registered!</Title>
      ) : formSubmitFailed === true ? (
        "Sorry, something went wrong. Please try again"
      ) : null}
    </Wrap>
  );
};

export default RegisterForLaunchForm;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
  padding: 0 20px;
`;

const Title = styled.div`
  width: 100%;
  padding: 25px;
  font-size: clamp(30px, 2vw, 40px);
  font-weight: 700;
  color: white;
  line-height: 1.25em;
`;

const Input = styled.input`
  font-size: 24px;
  margin-bottom: 18px;
  background-color: ${NUColors.darkNavy};
  color: ${NUColors.electricGreen};
  padding: 12px 12px;
  min-width: 300px;
  border: none;
  border-bottom: 1px solid ${NUColors.electricGreen};
  outline: none;
  border-radius: 5px;
`;

const SignUpButton = styled.button`
  margin-top: 20px;
  padding: 20px 50px;
  border-radius: 500px;
  outline: none;
  border: none;
  background-color: #00ffd1;
  color: ${NUColors.darkNavy};
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: white;
  }

  &:disabled {
    background-color: #ccc;
    color: ${NUColors.darkNavy};
  }
`;
