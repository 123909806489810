import React from "react";
import styled from "styled-components";

import HeroTitle from "./UI/HeroTitle";
import HeroSubTitle from "./UI/HeroSubTitle";
import HeroText from "./UI/HeroText";
import HeroWrap from "./UI/HeroWrap";

import Band from "../../assets/band.jpg";

const MainHero = (props) => {
  const { toggleModal } = props;
  return (
    <HeroWrap image={Band}>
      <HeroTitle>Streaming is Hurting Real Music</HeroTitle>
      <HeroSubTitle>
        Discover amazing new music curated by industry insiders and support the
        artists who make it
      </HeroSubTitle>
      <HeroText>From just £9.99/month</HeroText>
      <SignUpButton onClick={toggleModal}>Get Started</SignUpButton>
    </HeroWrap>
  );
};

export default MainHero;

const SignUpButton = styled.button`
  margin-top: 20px;
  padding: 20px 50px;
  border-radius: 500px;
  outline: none;
  border: none;
  background-color: #00ffd1;
  color: #0a172b;
  font-size: 30px;
  font-weight: 600;

  &:hover {
    color: white;
    cursor: pointer;
  }
`;
