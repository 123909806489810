import React from "react";
import styled from "styled-components";

const HeroWrap = (props) => {
  const { image, padding } = props;
  return (
    <Wrap image={image} padding={padding}>
      {props.children}
    </Wrap>
  );
};

export default HeroWrap;

const Wrap = styled.div`
  text-shadow: 10px 10px 50px #888;
  background-color: ${(props) => (props.color ? props.color : "none")};
  background-image: ${(props) =>
    props.image
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(125, 249, 255, 0.4)), url(${props.image})`
      : "none"};
  background-position: center;
  padding: ${(props) => (props.padding ? props.padding : "100px 0")};

  @media (max-width: 768px) {
    padding: 100px 0;
  }
`;
