import React from "react";
import styled from "styled-components";

import HeroTitle from "./UI/HeroTitle";
import HeroSubTitle from "./UI/HeroSubTitle";
import HeroText from "./UI/HeroText";

const ArtistHero = (props) => {
  const { toggleModal } = props;

  return (
    <TileWrap>
      <HeroTitle>~500,000 Monthly Streams</HeroTitle>
      <HeroSubTitle>
        Just for one artist to earn the minimum wage on streaming services
      </HeroSubTitle>
      <HeroText>You can change that by becoming a member</HeroText>
      <SignUpButton onClick={toggleModal}>Get Started</SignUpButton>
    </TileWrap>
  );
};

export default ArtistHero;

const TileWrap = styled.div`
  text-shadow: 10px 10px 50px #888;
  background: rgb(10, 23, 43);
  background: linear-gradient(
    140deg,
    rgba(10, 23, 43, 1) 70%,
    rgba(0, 255, 209, 1) 100%
  );

  padding: 200px 2px;

  @media (max-width: 768px) {
    padding: 100px 2px;
  }
`;

const SignUpButton = styled.button`
  margin-top: 20px;
  padding: 20px 50px;
  border-radius: 500px;
  outline: none;
  border: none;
  background-color: #00ffd1;
  color: #0a172b;
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;
