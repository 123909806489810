import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: null,
};

const userLoggedIn = (state, action) => {
  return {
    ...state,
    user: action.payload,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SIGNED_IN_SUCCESS:
      return userLoggedIn(state, action);
    default:
      return state;
  }
};

export default reducer;
