import React from "react";
import ReactDOM from "react-dom";
import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import firebase from "firebase/app";
import reportWebVitals from "./reportWebVitals";

// reducers
import authReducer from "./store/reducers/authReducer";
import { Provider } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyBWVqGGgi8hpo90pzczmtBY2tXI1tEw9lE",
  authDomain: "nujuke.firebaseapp.com",
  projectId: "nujuke",
  storageBucket: "nujuke.appspot.com",
  messagingSenderId: "862667041014",
  appId: "1:862667041014:web:8a42c21372b15c8b00e4d7",
};

firebase.initializeApp(firebaseConfig);

const appReducer = combineReducers({
  auth: authReducer,
});

// to clear state completely on log out
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
