import React from "react";

import DiscoveryHero from "./DiscoveryHero";
import MainHero from "./MainHero";
import FiveAlbumsTile from "./FiveAlbumsTile";
import ArtistHero from "./ArtistsHero";
import Footer from "./Footer";

const LandingPageView = (props) => {
  return (
    <React.Fragment>
      <MainHero {...props} />

      <FiveAlbumsTile />

      <DiscoveryHero {...props} />

      <FiveAlbumsTile />

      <ArtistHero {...props} />

      <Footer />
    </React.Fragment>
  );
};

export default LandingPageView;
