import { React, useState } from "react";
import styled from "styled-components";

const Input = (props) => {
  const { error } = props;
  const [hasError, setHasError] = useState(false);
  return <InputField></InputField>;
};

export default Input;

const InputField = styled.input`
  border-color: pink;
`;
