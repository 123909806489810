import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Route, BrowserRouter, Switch } from "react-router-dom";

// store
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "./store/actions/actionTypes";

// custom components
import PrivateRoute from "./PrivateRoute";
import SignUp from "./containers/Auth/SignUp/SignUp";
import LogIn from "./containers/Auth/LogIn/LogIn";
import Dashboard from "./containers/Dashboard/Dashboard";
import LandingPage from "./containers/LandingPage/LandingPage";

// css
import "./App.css";
import NavBar from "./containers/NavBar/NavBar";

function App() {
  // local state
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // store
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);

        dispatch({ type: actionTypes.USER_SIGNED_IN_SUCCESS, payload: user });

        // get token
        const token = await firebase.auth().currentUser.getIdToken(true);

        // save to local storage
        localStorage.setItem("token", token);

        setIsLoading(false);
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
        console.log("NO user");
      }
    });
  }, []);

  return (
    <div className="App">
      <NavBar></NavBar>

      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              return isLoading == true ? (
                "loading"
              ) : user !== null ? (
                <Dashboard />
              ) : (
                <LandingPage />
              );
            }}
          />

          <Route exact path="/login" component={LogIn} />
          <Route exact path="/signup" component={SignUp} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
