import { React, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import SignUpView from "../../../components/Auth/SignUp/SignUpView";
import input from "../../../components/UI/Input/Input";

const SignUp = (props) => {
  const [form, setForm] = useState({ email: null, password: null });

  const formChangedHandler = (e) => {
    console.log(e.target.name, e.target.value);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();
    try {
      const user = await firebase
        .auth()
        .createUserWithEmailAndPassword(form.email, form.password);

      console.log(user);
    } catch (error) {
      console.log("error creating user:::", error);
    }
  };

  console.log(form);

  return (
    // <div>
    //   <SignUpView
    //     formChangedHandler={formChangedHandler}
    //     submitFormHandler={submitFormHandler}
    //   ></SignUpView>
    // </div>
    <div>
      <label>email</label>
      <input name="email" onChange={formChangedHandler}></input>
      <label>password</label>
      <input name="password" onChange={formChangedHandler}></input>
      <button onClick={submitFormHandler}>Submit</button>
    </div>
  );
};

export default SignUp;
