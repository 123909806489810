import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

const NavBar = () => {
  const dispatch = useDispatch();
  const signUserOutHandler = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "USER_LOGOUT" });
      })
      .catch((error) => console.log(`Error signing out::: ${error}`));
  };
  return (
    <Wrap>
      <LogoWrap>NuJuke</LogoWrap>
      <NavItemsWrap>
        {/* <button onClick={signUserOutHandler}>Sign Out</button> */}
      </NavItemsWrap>
    </Wrap>
  );
};

export default NavBar;

const Wrap = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
`;

const LogoWrap = styled.div`
  font-family: "Bebas Neue";
  font-size: 60px;
  color: #00ffd1;
  font-weight: 900;
  letter-spacing: 5px;
`;

const NavItemsWrap = styled.div``;
